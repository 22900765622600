import { Vue } from 'vue-property-decorator';
export default class COMMON_CONST extends Vue {
    public static readonly ELE_ACCOUNT_NAME:string = 'name';
    public static readonly ELE_STREET_ADD_2:string = 'streetAddressLine2';
    public static readonly ELE_CITY:string = 'city';
    public static readonly ELE_STATE:string = 'state';
    public static readonly ELE_POSTAL_CODE:string = 'zipCode';
    public static readonly ELE_QUANTITY:string = 'quantity';
    public static readonly ELE_PROGRAM_NAME:string = 'ele_program_name';
    public static readonly ELE_PROGRAM_START_DATE:string = 'ele_program_start_date';
    public static readonly ELE_PROGRAM_END_DATE:string = 'ele_program_end_date';
    public static readonly ELE_SUBSCRIPTION_START_DATE:string = 'ele_subscription_start_date';
    public static readonly ELE_SUBSCRIPTION_END_DATE:string = 'ele_subscription_end_date';
    public static readonly ELE_NUM_OF_SCHOLAR:string = 'ele_num_of_scholars';
    public static readonly ELE_PROGRAM_DESCRIPTION: string = 'ele_program_description';
    public static readonly ELE_BX_PARTNER_SOL_LEAD_FIRST_NAME:string = 'ele_bx_partner_sol_lead_f_name';
    public static readonly ELE_BX_PARTNER_SOL_LEAD_LAST_NAME:string = 'ele_bx_partner_sol_lead_l_name';
    public static readonly ELE_BX_PARTNER_SOL_LEAD_EMAIL:string = 'ele_bx_partner_sol_lead_email';
    public static readonly ELE_BX_SUCCESS_LEAD_FIRST_NAME:string = 'ele_bx_success_lead_f_name';
    public static readonly ELE_BX_SUCCESS_LEAD_LAST_NAME:string = 'ele_bx_success_lead_l_name';
    public static readonly ELE_BX_SUCCESS_LEAD_EMAIL:string = 'ele_bx_success_lead_email';
    public static readonly ELE_PROGRAM_ADMIN_FIRST_NAME:string = 'ele_program_admin_f_name';
    public static readonly ELE_PROGRAM_ADMIN_LAST_NAME:string = 'ele_program_admin_l_name';
    public static readonly ELE_PROGRAM_ADMIN_EMAIL:string = 'ele_program_admin_email';
    public static readonly ELE_PROGRAM_ADMIN_SCHEDULE_DATE:string = 'ele_program_admin_schedule_date';
    public static readonly EDIT_PROGRAM:string = 'Edit Program';
    public static readonly INVITE_PROGRAM_ADMIN:string = 'InviteProgram';
    public static readonly ELE_PANORAMA_SKU:string = 'Panorama SKU';
    public static readonly ELE_SUBLICENSE_SKU:string = 'Sublicense SKU';
    public static readonly ELE_STREET_ADD_1:string = 'streetAddressLine1';
    public static readonly ELE_PROGRAM_ADMIN_PHONE:string = 'ele_program_admin_phone';
    public static readonly ELE_PROGRAM_ADMIN_NOTE:string = 'ele_program_admin_note';
    public static readonly EDIT_ACCOUNT:string = 'Edit Account';
    public static readonly ELE_SECRET:string = 'Secret Key';
    public static readonly ELE_SALESFORCE_ACCOUNT_ID:string = 'ele_salesforce_AccountId';   
}
